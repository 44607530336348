import React, { lazy, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useQuestionnaire } from './hooks';
import { CookiePro } from './cookie-pro/CookiePro';
import useMutationObserverForNonce from '../services/MutationObserver';
import { GTM } from './google-tag-manager/GTM';

const Home = lazy(() => import('../components/home/Home'));
const Start = lazy(() => import('./start/Start'));
const Questionnaire = lazy(() => import('../containers/Questionnaire'));
const Results = lazy(() => import('../containers/Results'));

const App = () => {


    
    useMutationObserverForNonce();  

    const { initialiseQuestionnaire } = useQuestionnaire();


    useEffect(() => {
        initialiseQuestionnaire();
    },[initialiseQuestionnaire])

    return (
        <>
       <CookiePro />
        <GTM />
        <Routes>

            <Route exact path="/" element={<Home />} />

            <Route exact path="/start" element={<Start />} />

            <Route exact path="/questionnaire" element={<Questionnaire />} />

            <Route exact path="/results" element={<Results />} />

            <Route path="*" element={<Navigate to="/" />} />

        </Routes>
        </>
    )
}

export default App;