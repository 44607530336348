import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import startQuestionnaire from '../actions/questionnaire/startQuestionnaire';
import getQuestions from '../actions/questions/getQuestions';
import { RESULTS_RESET } from '../actions/results/types';

export const useQuestionnaire = () => {
    const dispatch = useDispatch();

    const restartQuestionnaire = useCallback(async () => {
        dispatch({
            type: RESULTS_RESET,
        });
        await dispatch(await startQuestionnaire());
    }, [dispatch]);

    const initialiseQuestionnaire = useCallback(async () => {
        await dispatch(await getQuestions());
        await dispatch(await startQuestionnaire());
    }, [dispatch]);

    return {
        initialiseQuestionnaire,
        restartQuestionnaire,
    };
};
