import { useEffect } from 'react';
import useNonce from './useNonce';

const useMutationObserverForNonce = () => {
    const nonce = useNonce();

    useEffect(() => {
        if (!nonce) {
            console.warn('Nonce not available for MutationObserver setup.');
            return;
        }

        // Apply nonce to all existing <style> elements without a nonce on initial page load
        document.querySelectorAll('style:not([nonce])').forEach(styleNode => {
            if (!styleNode.dataset.helmet) {
                // Skip styles managed by Helmet
                styleNode.setAttribute('nonce', nonce);
            }
        });

        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                mutation.addedNodes.forEach(node => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        // Handle dynamically added <style> elements without a nonce
                        if (node.nodeName === 'STYLE' && !node.hasAttribute('nonce')) {
                            if (!node.dataset.helmet) {
                                // Skip Helmet managed elements
                                node.setAttribute('nonce', nonce);
                            }

                            // Verify if the nonce was applied successfully
                            if (!node.hasAttribute('nonce') || node.getAttribute('nonce') !== nonce) {
                                console.error('Failed to apply nonce to inline <style>:', node);
                            }
                        }

                        // Handle dynamically added <script> elements without a nonce
                        if (node.nodeName === 'SCRIPT' && !node.hasAttribute('src') && !node.hasAttribute('nonce')) {
                            if (!node.dataset.helmet) {
                                // Skip Helmet managed elements
                                node.setAttribute('nonce', nonce);
                            }

                            // Verify if the nonce was applied successfully
                            if (!node.hasAttribute('nonce') || node.getAttribute('nonce') !== nonce) {
                                console.error('Failed to apply nonce to inline <script>:', node);
                            }
                        }

                        // Handle dynamically added <link> elements (stylesheets only)
                        if (
                            node.nodeName === 'LINK' &&
                            node.getAttribute('rel') === 'stylesheet' &&
                            !node.hasAttribute('nonce')
                        ) {
                            if (!node.dataset.helmet) {
                                node.setAttribute('nonce', nonce);
                            }
                            if (!node.hasAttribute('nonce') || node.getAttribute('nonce') !== nonce) {
                                console.error('Failed to apply nonce to <link rel="stylesheet">:', node);
                            }
                        }

                        // Log a warning for inline `style` attributes
                        if (node.hasAttribute && node.hasAttribute('style')) {
                            console.warn(
                                `Inline style attribute detected on <${node.nodeName.toLowerCase()}> element. CSP may block this:`,
                                node
                            );
                        }
                    }
                });
            });
        });

        // Observe changes in the document's head and body for added nodes
        observer.observe(document.head, { childList: true, subtree: true });
        observer.observe(document.body, { childList: true, subtree: true });

        // Cleanup observer on component unmount
        return () => observer.disconnect();
    }, [nonce]);
};

export default useMutationObserverForNonce;
