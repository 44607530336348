// eslint-disable-next-line
/* global __webpack_nonce__ */
import React, { createContext, useEffect, useRef } from 'react';
import { generateNonce } from './services/nonceGenerate';

const NonceContext = createContext();

const newNonce = generateNonce();

export const NonceProvider = ({ children }) => {
    const renderCount = useRef(0);

    const renderCheck = process.env.NODE_ENV === 'development' ? 2 : 1;

    useEffect(() => {
        renderCount.current += 1;

        if (renderCount.current >= renderCheck) {
            const Region = process.env.REACT_APP_AWS_REGION;

            // Create and append the CSP meta tag
            const cspMetaTag = document.createElement('meta');
            cspMetaTag.httpEquiv = 'Content-Security-Policy';
            cspMetaTag.content = `upgrade-insecure-requests; connect-src 'self' *.appsync-api.${Region}.amazonaws.com cognito-idp.${Region}.amazonaws.com *.auth.${Region}.amazoncognito.com cognito-identity.${Region}.amazonaws.com *.google-analytics.com:443 https://www.googletagmanager.com *.onetrust.com; frame-src *.risk.reassure.co.uk; font-src fonts.gstatic.com; img-src 'self' data: https://www.google-analytics.com https://www.googletagmanager.com https://cdn-ukwest.onetrust.com; object-src 'none'; default-src 'self';  style-src 'self' 'unsafe-inline' cdn.cookielaw.org cookie-cdn.cookiepro.com *.onetrust.com fonts.googleapis.com; script-src 'self' 'unsafe-inline' *.risk.reassure.co.uk https://www.google-analytics.com https://www.googletagmanager.com *.onetrust.com https://cdn.cookielaw.org https://cookie-cdn.cookiepro.com`;
            document.head.insertBefore(cspMetaTag, document.head.firstChild);

            window.__webpack_nonce__ = newNonce;
        }
    }, [renderCheck]);

    return <NonceContext.Provider value={newNonce}>{children}</NonceContext.Provider>;
};

export default NonceContext;
