import { useEffect } from 'react';
import useNonce from '../../services/useNonce';

export const GTM = () => {
    const nonce = useNonce(); 
    const gtmId = process.env.REACT_APP_GTM_ACCOUNT; 

    useEffect(() => {
        if (!gtmId || !nonce) {
            return;
        }

        
        if (document.querySelector(`script[src*="googletagmanager.com/gtm.js?id=${gtmId}"]`)) {
            return;
        }

        // Create and append the GTM script
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
        script.setAttribute('nonce', nonce);

        document.head.appendChild(script);

       

        return () => {
            // Cleanup: Remove the script if needed
            if (script.parentNode) {
                script.parentNode.removeChild(script);
               
            }
        };
    }, [nonce, gtmId]);

    return null; 
};
